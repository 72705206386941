@import "../../App.scss";

.dropdown-paper {
  min-width: 70px;
  align-items: left;
}
.dark-mode .dropdown-paper {
  background-color: $bqa-dark-grey;
}
.dark-mode .dropdown-paper {
  background-color: $bqa-light-grey;
}

.dropdown-menu-item {
  width: 100% !important;
  font-weight: bold !important;
}
.dark-mode .dropdown-menu-item {
  color: $bqa-light-blue !important;
  background-color: $bqa-dark-grey !important;
  &:hover {
    background-color: #333333 !important;
  }
}
.light-mode .dropdown-menu-item {
  color: $bqa-dark-blue !important;
  background-color: $bqa-light-grey !important;
  &:hover {
    background-color: #bbbbbb !important;
  }
}
