.settings-container {
  justify-content: center;
}

.settings-top-sticky {
  position: sticky;
  top: 0px;
  padding-bottom: 10px;
  padding-top: 15px;
  width: max(100%, 350px);
}
.light-mode .settings-top-sticky {
  background-color: white;
}
.dark-mode .settings-top-sticky {
  background-color: black;
}
.settings-scrolling {
  margin-left: 10px;
  min-width: 350px;
  max-width: 400px;
  border: 1px solid grey;
  border-radius: 6px;
  padding: 20px 10px;
  margin-bottom: 10px;
  min-height: 200px;
}
