@import "../../App.scss";

.quiz-clickable {
  min-height: 145px !important;
  max-height: 145px !important;
  border: 1.5px solid white;
  outline: none;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  padding: 1px;
  text-decoration: none;
  width: 100%;
  display: inline-block;
}
.dark-mode .quiz-clickable {
  border-color: white;
  &:hover {
    border-color: $bqa-light-blue;
    color: $bqa-light-blue;
  }
  &:focus {
    border-color: $bqa-light-blue;
    color: $bqa-light-blue;
  }
}
.light-mode .quiz-clickable {
  border-color: black;
  &:hover {
    border-color: $bqa-dark-blue;
    color: $bqa-dark-blue;
  }
  &:focus {
    border-color: $bqa-dark-blue;
    color: $bqa-dark-blue;
  }
}

.season-event-list {
  font-size: 18px;
  border-left: 8px solid black;
  padding: 4px 0px 0px 8px;
  cursor: pointer;
  height: 40px;
  margin-bottom: 2px;
  min-width: 200px;
  text-decoration: none;
  text-align: left;
  outline: none;
  display: block;
}
.dark-mode .season-event-list {
  color: $bqa-light-blue;
  background-color: black;
  border-left-color: black;
  &:hover {
    border-color: $bqa-light-blue;
  }
  &-active {
    border-color: $bqa-blue;
    font-weight: bold;
  }
}
.light-mode .season-event-list {
  color: $bqa-dark-blue;
  background-color: white;
  border-left-color: white;
  &:hover {
    border-color: $bqa-dark-blue;
  }
  &-active {
    border-color: $bqa-blue;
    font-weight: bold;
  }
}
.season-event-list-active:hover {
  border-color: $bqa-blue !important;
}

.separator-line {
  width: 100%;
  height: 2px;
}
.dark-mode .separator-line {
  background-color: $bqa-dark-grey;
}
.light-mode .separator-line {
  background-color: $bqa-light-grey;
}
.separator-line-small {
  width: 100%;
  height: 0px;
  margin: 0px;
}
.dark-mode .separator-line-small {
  background-color: $bqa-dark-grey;
}
.light-mode .separator-line-small {
  background-color: $bqa-light-grey;
}

.team-container {
  width: 100%;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.box-score-item {
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.box-score-points-errors {
  text-align: center;
}

.dark-mode .received-characters {
  color: orange;
}
.light-mode .received-characters {
  color: #cc6c00;
}
.dark-mode .received-characters-background {
  background-color: orange;
}
.light-mode .received-characters-background {
  background-color: #cc6c00;
}
