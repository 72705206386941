.dark-mode .pbp-blue {
  background-color: rgb(0, 0, 70);
}
.light-mode .pbp-blue {
  background-color: rgb(80, 80, 255);
}

.dark-mode .pbp-red {
  background-color: rgb(70, 0, 0);
}
.light-mode .pbp-red {
  background-color: rgb(255, 80, 80);
}

.dark-mode .pbp-green {
  background-color: rgb(0, 70, 0);
}
.light-mode .pbp-green {
  background-color: rgb(50, 210, 50);
}
