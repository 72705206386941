@import "../../App.scss";

.check {
  margin: 0;
  outline: none;
  height: 42px;
  padding-top: 4px;
  padding-left: 8px;
  cursor: pointer;
  margin-bottom: 3px;
  border-radius: 4px;
  font-size: 16px;
  width: min(100%, 300px);
  text-align: left;
  font-size: 16px;
  transition: 0.1s;
}
.light-mode .check {
  border: 2px solid white;
}
.dark-mode .check {
  border: 2px solid black;
}

.checked {
  font-weight: bold;
  background-color: $bqa-blue !important;
  color: white !important;
}

.dark-mode .unchecked {
  background-color: $bqa-dark-grey;
  color: $bqa-light-blue;
}
.light-mode .unchecked {
  background-color: $bqa-light-grey;
  color: $bqa-dark-blue;
}

.outline {
  border-color: $bqa-light-blue;
}
.dark-mode .outline {
  border-color: $bqa-light-blue;
}
.light-mode .outline {
  border-color: $bqa-dark-blue;
}
