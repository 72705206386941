$bqa-light-blue: #add3e8;
$bqa-blue: #1c00ff;
$bqa-dark-blue: #0f0570;
$bqa-dark-grey: #333333;
$bqa-light-grey: #cccccc;

.App {
  -webkit-overflow-scrolling: touch;
  min-height: 100dvh;
}
.light-mode {
  color: black;
  background-color: white;
}
.dark-mode {
  color: lightgrey;
  background-color: black;
}

.page {
  padding: 24px 10px 40px 20px;
}
.dark-mode .page {
  background-color: black;
}
.light-mode .page {
  background-color: white;
}

/* Overriding CSS in native HTML tags, and applying light and dark mode versions */

p {
  margin: 10px 0px;
}
ul {
  padding: 0;
}
h1 {
  font-size: 22px;
  font-weight: normal;
  margin-top: 3px;
}
.dark-mode h1 {
  color: $bqa-light-blue;
}
.light-mode h1 {
  color: $bqa-dark-blue;
}
h2 {
  margin-top: 0;
  font-size: 14px;
  font-weight: bold;
}
h3 {
  font-size: 16px;
  margin-bottom: 4px;
}
h4 {
  font-size: 18px;
  font-weight: normal;
}

a {
  text-decoration: underline;
}
.dark-mode a {
  color: $bqa-light-blue;
}
.light-mode a {
  color: $bqa-dark-blue;
}

hr {
  border: 2px solid $bqa-dark-grey;
}
.dark-mode hr {
  border-color: $bqa-dark-grey;
}
.light-mode hr {
  border-color: $bqa-light-grey;
}

li {
  margin-left: 20px;
  list-style-type: initial;
}

button {
  transition: 0.3s;
  border: none;
  cursor: pointer;
  margin: 0px;
  padding: 0px 4px;
}
.dark-mode button {
  color: $bqa-light-grey;
}
.light-mode button {
  color: $bqa-dark-grey;
}

select,
input,
textarea {
  font-size: 16px;
  height: 24px;
  min-width: 150px;
}
.dark-mode select,
input,
textarea {
  color: black;
  background-color: #eeeeee;
  border-color: #222222;
}
.light-mode select,
input,
textarea {
  color: black;
  background-color: #eeeeee;
  border-color: #cccccc;
}

.field-label {
  width: 140px;
  display: inline-block;
}

/* Containers */
.main-box {
  border: 4px solid $bqa-dark-grey;
  border-radius: 8px;
  padding: 10px;
}
.dark-mode .main-box {
  border-color: $bqa-dark-grey;
}
.light-mode .main-box {
  border-color: $bqa-light-grey;
}

/* Things to click on */

.link {
  font-weight: bold;
  font-size: 16px;
  transition: 0.3s;
  transition-property: color;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  background-color: transparent;
  text-decoration: none;
}
.dark-mode .link {
  color: $bqa-light-blue;
  &:hover {
    color: $bqa-blue;
  }
  &:focus {
    color: $bqa-blue;
  }
}
.light-mode .link {
  color: $bqa-dark-blue;
  &:hover {
    color: $bqa-blue;
  }
  &:focus {
    color: $bqa-blue;
  }
}

.clickable {
  display: inline-block;
  box-sizing: border-box;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  width: 160px;
  height: 36px;
  border-radius: 8px;
  padding-top: 6px;
  text-align: center;
  transition: 0.3s;
  transition-property: background-color;
  text-decoration: none;
  outline: none;
  border: none;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.dark-mode .clickable {
  color: white;
  background-color: $bqa-blue;

  &:hover {
    background-color: $bqa-dark-blue;
  }
  &:focus {
    background-color: $bqa-dark-blue;
  }
}
.light-mode .clickable {
  color: white;
  background-color: $bqa-blue;

  &:hover {
    background-color: $bqa-dark-blue;
  }
  &:focus {
    background-color: $bqa-dark-blue;
  }
}

/* Most commonly a back button, but can be a secondary button as well */
.back-button {
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  transition: 0.3s;
  transition-property: border-color, color;
  outline: none;
  background: none;
  text-align: center;
  height: 36px;
  border-radius: 8px;
  padding: 6px 12px;
  text-decoration: none;
  text-wrap: nowrap;
}
.dark-mode .back-button {
  border: 2px solid $bqa-light-blue;
  color: $bqa-light-blue;
  &:hover {
    color: $bqa-blue;
    border-color: $bqa-blue;
  }
  &:hover {
    color: $bqa-blue;
    border-color: $bqa-blue;
  }
}
.light-mode .back-button {
  border: 2px solid $bqa-dark-blue;
  color: $bqa-dark-blue;
  &:hover {
    color: $bqa-blue;
    border-color: $bqa-blue;
  }
  &:hover {
    color: $bqa-blue;
    border-color: $bqa-blue;
  }
}

.disabled-clickable {
  font-size: 16px;
  font-weight: bold;
  background-color: grey !important;
  color: white !important;
  width: 160px;
  height: 36px !important;
  border-radius: 7px;
  padding-top: 11px;
  padding-bottom: 7px;
  text-align: center;
  transition: 0.3s;
  transition-property: background-color;
  outline: none;
  border: none;
}

.jump-button {
  position: fixed;
}

/* Dropdown */
.dropdown-menu {
  padding: 5px 5px;
  z-index: 3;
  background-color: $bqa-dark-grey;
  cursor: initial;
}
.dropdown-clickable:focus {
  outline: none;
  border: none;
}
.dropdown button {
  max-width: 100%;
}

/* Text */

.important-information {
  color: red;
  font-size: 18px !important;
  font-weight: normal;
}

.no-wrap-text {
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

/* Misc */
.slider {
  width: 100%;
  cursor: pointer;
}
