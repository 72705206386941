@import "../../App.scss";

.tournament-page {
  @extend .page;
  min-width: 100%;
  display: inline-block;
}
.quiz-wrapper {
  border: 2px solid $bqa-dark-grey;
  border-radius: 8px;
  outline: none;
  position: absolute;
  text-align: start;
  background-color: transparent;

  .team-row {
    margin: 4px 0px;
    font-size: 14px;

    span {
      @extend .no-wrap-text;
      display: inline-block;
    }
  }
}
.dark-mode .quiz-wrapper {
  border-color: $bqa-dark-grey;
}
.light-mode .quiz-wrapper {
  border-color: $bqa-light-grey;
}

.round-site-header {
  position: absolute;
  border-radius: 4px;
  font-weight: bold;
  font-size: 18px;
  // Center text horizontally and vertically
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}
.dark-mode .round-site-header {
  background-color: $bqa-dark-grey !important;
}
.light-mode .round-site-header {
  background-color: $bqa-light-grey !important;
}

.single-quiz-view {
  margin-top: 20px;

  .quiz-team {
    @extend .no-wrap-text;
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.link-icon {
  border: 2px solid lightgrey;
  border-radius: 2px;
  padding: 4px;
  width: 35px;
  height: 35px;
  transition: 0.3s;
}
.dark-mode .link-icon {
  border: 2px solid $bqa-dark-grey;
  color: grey;
  &:hover {
    color: $bqa-dark-grey;
  }
  &:focus {
    color: $bqa-dark-grey;
  }
}
.light-mode .link-icon {
  border: 2px solid $bqa-light-grey;
  color: grey;
  &:hover {
    color: $bqa-light-grey;
  }
  &:focus {
    color: $bqa-light-grey;
  }
}

.dark-mode .black-line-of-death {
  background-color: #888888;
}
.light-mode .black-line-of-death {
  background-color: #333333;
}
