@import "../../App.scss";

.seat-display {
  padding: 2px;
  text-align: left;
}

.errors-container {
  height: 30px;
  margin-top: 6px;
  text-align: center;
  font-size: 20px;
}
.dark-mode .middle-errors {
  background-color: #666600;
}
.light-mode .middle-errors {
  background-color: #aaaa00;
}
.dark-mode .high-errors {
  background-color: darkred;
}
.light-mode .high-errors {
  background-color: #dd0000;
}
// For Show Team
.dark-mode .middle-errors-show {
  color: #999900;
}
.light-mode .middle-errors-show {
  color: #aaaa00;
}
.dark-mode .high-errors-show {
  color: darkred;
}
.light-mode .high-errors-show {
  color: #dd0000;
}

.numpad-button {
  outline: none;
  font-weight: bold;
  font-size: 16px;
  width: calc((100% - 12px) / 3);
  height: 42px;
  margin-right: 6px;
  cursor: pointer;
  transition: 0.2s;
  margin-top: 2px;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.dark-mode .numpad-button {
  background-color: black;
  border: 1px solid white;
  color: $bqa-light-grey;
  &:hover {
    background-color: $bqa-dark-grey;
  }
  &:focus {
    background-color: $bqa-dark-grey;
  }
}
.light-mode .numpad-button {
  background-color: white;
  border: 1px solid black;
  color: $bqa-dark-grey;
  &:hover {
    background-color: $bqa-light-grey;
  }
  &:focus {
    background-color: $bqa-light-grey;
  }
}

.numpad-reference-display {
  font-size: 20px;
  height: 35px;
  padding: auto;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}
.dark-mode .numpad-reference-display {
  background-color: white;
  color: black;
  &:hover {
    background-color: $bqa-light-grey;
  }
}
.light-mode .numpad-reference-display {
  background-color: black;
  color: white;
  &:hover {
    background-color: #555555;
  }
}

.word-button {
  font-size: 16px;
  border: 1px solid $bqa-light-blue;
  padding: 6px 12px;
  height: 35px;
  min-width: 50px;
  margin-bottom: 6px;
  width: 110px;

  .word-count-span {
    font-size: 12px;
    margin-right: 2px;
  }
}
.dark-mode .word-button {
  border-color: $bqa-light-blue;
  color: $bqa-light-blue;
  background-color: black;
  &:focus {
    border: 1px solid $bqa-blue;
    color: $bqa-blue;
  }
  &:hover {
    border: 1px solid $bqa-blue;
    color: $bqa-blue;
  }
}
.light-mode .word-button {
  border-color: $bqa-dark-blue;
  color: $bqa-dark-blue;
  background-color: white;
  &:focus {
    border: 1px solid $bqa-blue;
    color: $bqa-blue;
  }
  &:hover {
    border: 1px solid $bqa-blue;
    color: $bqa-blue;
  }
}

.error-choice-button {
  padding: 1px;
  font-size: 14px;
  cursor: pointer;
  background-color: darkred !important;
  width: calc(100% / 6);
  text-align: center;
  border: 1px solid black;
  transition: 0.3s;
  overflow: hidden;
}
.error-choice-button:hover {
  background-color: rgb(100, 0, 0) !important;
}
