@import "../../App.scss";

.menu-footer {
  position: fixed;
  height: 43px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 1px;
}
.light-mode .menu-footer {
  background-color: $bqa-light-grey;
}
.dark-mode .menu-footer {
  background-color: $bqa-dark-grey;
}

.main-view-container {
  margin-top: 40px;

  margin-bottom: 60;
}
