.stats-settings-col {
  min-width: 300px;
  max-width: 300px;
  margin-right: 50px;
}
.stats-settings-col .stats-settings-header {
  height: 90px;
}
.stats-settings-header h2 {
  font-size: 16px;
}
.stats-settings-header input {
  min-width: 20px;
}
.stats-settings-col .link {
  margin-bottom: 10px;
  margin-left: -4px;
}
.stats-settings-scrolling {
  margin-left: 0px;
  max-height: calc(100vh - 300px);
  max-width: 320px !important;
  min-width: 320px !important;
  overflow-y: auto;
}
