.modal-component {
  h2 {
    font-size: 16px;
  }
}
.light-mode {
  .modal-backdrop {
    background-color: #555555;
  }
  .modal-box {
    background-color: white;
  }
}

.dark-mode {
  .modal-backdrop {
    background-color: #444444;
  }
  .modal-box {
    background-color: black;
  }
}
